<template>
  <div class="cost-analysis">
    <div class="page-header">
      <h2>成本类型分析</h2>
    </div>
    <div class="page-content">
      <!-- 筛选条件 -->
      <el-form :inline="true" class="filter-form">
        <el-form-item>
          <el-select v-model="selectedType" placeholder="成本类型">
            <el-option
              v-for="item in costTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAnalyze">分析</el-button>
        </el-form-item>
      </el-form>

      <!-- 成本分析图表 -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="chart-card">
            <div slot="header">成本构成</div>
            <div class="chart" ref="pieChart"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="chart-card">
            <div slot="header">成本趋势</div>
            <div class="chart" ref="lineChart"></div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 成本明细表格 -->
      <el-card class="cost-table" style="margin-top: 20px;">
        <div slot="header">成本明细</div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="type" label="成本类型"></el-table-column>
          <el-table-column prop="amount" label="金额"></el-table-column>
          <el-table-column prop="proportion" label="占比"></el-table-column>
          <el-table-column prop="yoy" label="同比"></el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CostAnalysis',
  data() {
    return {
      selectedType: '',
      dateRange: [],
      costTypes: [
        { value: 'material', label: '原材料成本' },
        { value: 'labor', label: '人工成本' },
        { value: 'operation', label: '运营成本' }
      ],
      tableData: []
    }
  },
  methods: {
    handleAnalyze() {
      // 实现分析逻辑
    },
    initCharts() {
      // 初始化图表
    }
  },
  mounted() {
    this.initCharts()
  }
}
</script>

<style scoped>
.cost-analysis {
  padding: 20px;
}
.page-header {
  margin-bottom: 20px;
}
.filter-form {
  margin-bottom: 20px;
}
.chart-card {
  margin-bottom: 20px;
}
.chart {
  height: 300px;
}
</style> 